.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    min-height: 100vh;
    background-color: var(--white-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: var(--main-color);
    padding: 0 10px;
    transition: transform .3s ease;
    font-family: 'roboto';
    z-index: 100;
}

.sidebar--darkBg {
    background-color: var(--main-color);

}

.sidebar--darkBg .sidebar__menu-list-element, .sidebar--darkBg .sidebar__user-name {
    color: var(--white-color);

}

.sidebar.sidebar--open {
    transform: translateX(0);
    transition: transform .3s ease;
}

.sidebar__user-div, .sidebar__menu-list-element {
    padding: 20px 5px;
    border-bottom: 1px solid var(--secondary-color);

}

.sidebar__menu-list-element  {
    text-transform: uppercase;
}

.sidebar__user-name {
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 1.8rem;
    margin-bottom: 4px;
}

.sidebar__course-flag-img {
    margin: 5px auto;
    width: 60px;
}

.sidebar__menu-list {
    list-style: none;
}

.sidebar__menu-list-element {
    cursor: pointer;
    color: var(--main-color);
    position: relative;
}
.sidebar__menu-list-inbox {
    position: relative;
}
.sidebar__menu-list-inbox--dot {
    padding-right: 10px;
}
.sidebar__menu-list-inbox--dot::after {
    content: '';
    display: block;
    position: absolute;
    background: var(--secondary-color);;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: -3px;
    right: -3px;
}

.sidebar__menu-list-element--help {
    position: relative;
}

.sidebar__menu-list-element--help:hover .sidebar__info-div{
    display: block;
}

.sidebar__info-div {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 100%;
    margin-right: 15px;
    background-color: var(--white-color);
    width: 300px;
    padding: 10px 20px;
    border-radius: 5px;
}

.sidebar__info-text {
    font-family: 'Roboto';
    text-transform: none;
    color: #000;
}

.sidebar__span-block {
    display: block;
}

.sidebar__logo {
    width: 100px;
    margin-bottom: 10px;
}

.sidebar__opening-div {
    position: absolute;
    top: 0;
    right: 100%;
    padding: 10px;
    background-color: var(--main-color);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:  0 0 0 5px;
    cursor: pointer;
    font-size: 2rem;
} 
